import { Spinner } from "@fluentui/react-components";
import React, { useState, useEffect } from "react";
import CopilotMeet from "./CopilotMeet";
import MeetingInformation from "./MeetingInformation";
import MeetingInformationMobile from "./MeetingInformationMobile";

const MeetingContainer = ({activeTab}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [freePlan, setFreePlan] = useState(false);
  const [licenseAndPlanDetails, setLicenseAndPlanDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  
console.log('this is the tab from from mark ',activeTab)

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/settings?platform=msteams`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log('this is the settings',data)

      const receivedLicenseAndPlanDetails =
        data.data.licenseAndPlanDetails || {};

      setLicenseAndPlanDetails(receivedLicenseAndPlanDetails);

      setFreePlan(
        data.freePlan || receivedLicenseAndPlanDetails.freePlan || false
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <Spinner label="Loading..." size="large" className="loading" />;
  }

  return isMobile ? (
    <>
            {!licenseAndPlanDetails ? (
                <CopilotMeet />
            ) : (
                <>
              {licenseAndPlanDetails.isAudioCallEnabled && !freePlan ? (

                <MeetingInformationMobile currentTab={activeTab}/>
              ) : (
                <CopilotMeet />
                    )}
                </>
      )}
    </>
  ) : (
    <>
            {!licenseAndPlanDetails ? (
                <CopilotMeet />
            ) : (
                <>
              {licenseAndPlanDetails.isAudioCallEnabled && !freePlan ? (

                <MeetingInformation  currentTab={activeTab}/>
              ) : (
                <CopilotMeet />
                    )}
                </>
      )}
    </>
  );
};


export default MeetingContainer;
