import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Field,
  Input,
  Tooltip,
} from "@fluentui/react-components";
import { AddCircle24Regular} from "@fluentui/react-icons";

export const AddMeetDialog = (props) => {
  console.log(" AddMeetDialog ~ props:", props);
  const [inputValue, setInputValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [validMeet, setValidMeet] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleSubmit = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const requestBody = JSON.stringify({ link: inputValue });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/multi-plaform/meet`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }, body: requestBody,
        }
      );
      const data = await response.json();    
      if (data.status === "failure") {
        const errmsg = data.data.message;
        setValidMeet("error");
        setErrorMessage(errmsg);
        setInputValue("");
      } else {
        setIsOpen(false);
        props.setMeetAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setValidMeet("");
    setErrorMessage("");
  };
  const handleClose=()=>{
    setIsOpen(false);
    setInputValue('')
    setValidMeet('')
    setErrorMessage('')
  }
  return (
    <Dialog open={isOpen} onDismiss={() => setIsOpen(false)}>
      <DialogTrigger disableButtonEnhancement>
        <Tooltip content="Add Mark to a live meeting">
          <Button
            style={{ width: 30 }}
            appearance="primary"
            onClick={() => setIsOpen(true)}
          >
            <AddCircle24Regular />
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Meeting Link</DialogTitle>
          <DialogContent>
            <Field
              validationState={validMeet}
              validationMessage={errorMessage}
              required
              hint="Invite Mark to join either Microsoft Teams or Google Meet conference calls by giving a live meeting link"
            >
              <Input
                value={inputValue}
                onChange={handleInputChange}
                placeholder="https://teams.microsoft.com/l/meetup-join/..."
              />
            </Field>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button appearance="primary" onClick={handleSubmit}>
              Invite
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};