import React from "react";
import {
  ChevronRight20Filled,
  Open16Regular,
  Video28Regular,
  Calendar20Regular,
} from "@fluentui/react-icons";
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Link,
  Subtitle2Stronger,
} from "@fluentui/react-components";
import { Stack, Text } from "@fluentui/react";
import "./css/meetingcard.css";
import CustomBadge from "./CustomBadge";

const MeetingCard = ({
  upcomingMeetings,
  filteredMeetings,
  handleCancelCall,
  handleEndCall,
  handleRowClick,
  handleProcessCall,
  buttonClicked,
  completedMeetings,
}) => {
  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      {upcomingMeetings.length === 0 &&
        completedMeetings.length === 0 &&
        filteredMeetings.length === 0 && (
          <Text className="no-meetings-message">No meetings found.</Text>
        )}
      {filteredMeetings.map((item) => (
        <Card
          key={item._id}
          className={`meeting-card ${
            buttonClicked ? "button-clicked-card" : ""
          }`}
          onClick={ item.status === "COMPLETED" && item.metadata && item.metadata.length > 0
                  ? () => handleRowClick(item)
                  : undefined}
        >
         <CardHeader
    image={<Video28Regular />}
    header={
        <>
            <Subtitle2Stronger>{item.meetingName}</Subtitle2Stronger>
            {item.status === "COMPLETED" && item.metadata && item.metadata.length > 0 && (
                <ChevronRight20Filled />
            )}
        </>
    }
/>
          <Stack horizontal style={{ alignItems: "center" }}>
            <Text className="card-body ">Date and Time : </Text>

            <Calendar20Regular />
            <Text>  
              {new Date(item.meetingTime)
                .toLocaleString("en-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true, // To display in 12-hour format
                  timeZone: item.timezone, // Assuming item.timezone contains the timezone value
                })
                .replace(",", " ")}
            </Text>
          </Stack>

          <Stack horizontal style={{ alignItems: "center" }}>
            <Text className="card-body">Status: </Text>
            <CustomBadge status={item.status}></CustomBadge>
            <Text>
            {item.status
                    .split("_")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
            </Text>
          </Stack>

          <CardFooter className="card-footer">
            <Button icon={<Open16Regular />}>
              <Link
                href={item.meetingLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Meet
              </Link>
            </Button>
            <Stack>
              {item.status === "UPCOMING" && (
                <Button
                  onClick={() => handleCancelCall(item.id, item.workFlowId)}
                >
                  Cancel Call
                </Button>
              )}
              {item.status === "IN_PROGRESS" && (
                <Button
                  onClick={() =>
                    handleEndCall(item._id, item.userId, item.sessionId)
                  }
                >
                  End Call
                </Button>
              )}
              {item.status === "FAILED" && (
                <Button
                  onClick={() =>
                    handleProcessCall(item.id, item.userId, item.sessionId)
                  }
                >
                  Process Call
                </Button>
              )}
              
            </Stack>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default MeetingCard;